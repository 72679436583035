import Typewriter from "typewriter-effect";
import { Analytics } from "@gashon/analytics";

import { CanvasSimulation } from "./features";

export default function App() {
  const isMobile = window.innerWidth < 768;

  return (
    <div className="relative w-screen h-screen">
      <Analytics
        apiKey="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JfaWQiOiJmZTBmZDNiMC1kOWI1LTQ0NDMtYmRkNy0wYWY1ZDVlOTU4OTIiLCJwcm9qZWN0X2lkIjoiZTkyNjA4YjAtOTZmYi00OTQ1LWE5NTItOTA0MTMwNmNhODMwIiwiY3JlYXRlZF9hdCI6IjIwMjMtMTEtMjJUMjM6NTQ6NTUuMzUzWiIsImlhdCI6MTcwMDY5NzI5NX0.hzuoiQevxOmbZhCzSnYYUYpy6NF9-SNBbTqEGmj2lqs"
        endpoint="https://analytics-fawn-nine.vercel.app/api/analytics"
      />
      <CanvasSimulation />
      <div className="absolute top-8 left-8 lg:top-32 lg:left-32 text-black text-7xl lg:text-9xl ">
        <div>
          Hi, {!isMobile && <br></br>}
          {isMobile && " I'm"}
          <h1 className="flex flex-row gap-1 lg:gap-5 ">
            {!isMobile && "I'm"}
            <Typewriter
              options={{
                strings: [
                  " Gashon!",
                  "  a Builder.",
                  " a Student.",
                  " a Researcher.",
                ],
                autoStart: true,
                loop: true,
                wrapperClassName: "",
              }}
            />
          </h1>
        </div>
      </div>
    </div>
  );
}
